.unauthorized-container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
}

.background-image-container {
  width: 100%;
  height: 100%;
  z-index: 99;

  position: fixed;
  top: 0;
  left: 0;
}

.background-image-container__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content-container {
  z-index: 100;
  padding: 40px;
}

.content-container__logo {
  width: 64px;
  height: 64px;
}

.content-container__title {
  color: #fff;
  font-size: 33px;
  font-weight: bold;
  font-family: Georgia, "Times New Roman", Times, serif;

  margin-top: 70px;
}

.content-container__title-divider {
  width: 60px;
  border: 2px solid #ffffff;
  opacity: 1;
  margin-left: 0px;
  margin-top: 40px;
}

.content-container__subtitle {
  color: #fff;
  font-size: 20px;

  margin-top: 75px;
}

.content-container__engagement-leader {
  margin-top: 40px;
  display: flex;
}