.globalFooter {

}

.globalFooterSocial {
  background: #2d3c59;
  padding: 1.875em 0 2.188em;
}

.globalFooterSocialList {
  display: flex;
  margin: 0;
  list-style-type: none;

  font-size: 19px;
  color: #404041;
  padding: 0;

  justify-content: center;
  gap: 16px;
}

.globalFooterSocialListItem {
  font-size: 1.75em;
  flex: 0 0 auto;
  margin-bottom: 0!important;
  line-height: 0;
  display: list-item;
}

.globalListItemA {
  height: 56px;
  width: 56px;
  border-radius: 100%;
  border: 1px solid #fff;
  text-align: center;
  /* display: inline-block; */
  position: relative;

  text-decoration: none;
  line-height: inherit;
  transition: color .3s, background-color .3s;
  word-wrap: break-word;

  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.globalFooterNav {
  padding: 56px 0;

  background: #1B2435;
}

.globalFooterNavContainer {
  max-width: 1190px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.globalFooterNavWrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: calc((100%/12) * 10);
  margin: auto;
}

.globalFooterNavTitle {
  color: #FFFFFF;
  font-weight: 500;
  margin: 0 0 0.875em;
  font-family: MuseoSans-700;
  font-size: 18px;

  line-height: 1.25;

  font-style: normal;

  letter-spacing: 1px;
  padding: 0;
}

.globalFooterNavKnowUsGetStarted {
  flex: 1 1 calc(((100%/10) * 2) - 30px);
  margin-right: 30px
}

.globalFooterNavNoBullets {
  font-size: 19px;
  color: #404041;
  padding: 0;
  margin: 0 0 1.25em;
  list-style-type: disc;
  
  margin-left: 0;

  margin-top: 0;
}

.globalFooterNavLi {
  list-style: none !important;
  padding-left: 0 !important;

  margin-left: 0 !important;

  font-size: 16px;
  margin-bottom: 0.5em;

  display: list-item;
  line-height: 1.25;
}

.globalFooterNavA {
  color: #FFFFFF;

  text-decoration: none;
  line-height: inherit;
  transition: color .3s, background-color .3s;
  word-wrap: break-word;
}

.globalFooterFinePrint {
  flex: 2 1 calc((100%/10) * 5);
}

.globalFooterUnderlineA {
  text-decoration: underline;
  color: #FFFFFF;
  line-height: inherit;
  transition: color .3s, background-color .3s;
  word-wrap: break-word;
}

.globalFooterNavA:hover, .globalFooterUnderlineA:hover{
  color: #FFFFFF;
  text-decoration: underline;
  
  cursor: pointer;

  outline: 0;
}

.globalFooterFinePrintP {
  font-size: 14px;
  margin-bottom: 0.875em;
  font-family: MuseoSans-300;

  color: #FFFFFF;

  font-weight: 400;
  line-height: 1.35;
  padding: 0;
  margin-top: 0;
}

.globalFooterDisclaimer {
  flex: 1 0 100%;
  padding-top: 46px;
  font-style: normal !important;
}

.globalFooterDisclaimerP {
  color: #FFFFFF;
  font-size: 12px;

  font-weight: 400;
  line-height: 1.35;
  padding: 0;
  margin-top: 0;
  margin-bottom: 1.25em;
}

.globalFooterDisclaimerLastChildP {
  color: #FFFFFF;
  font-size: 12px;

  font-weight: 400;
  line-height: 1.35;
  padding: 0;
  margin-top: 0;
}