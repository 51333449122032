.no-access-error-boundary {
    background-color: #2D3B59;
    position: relative;
}

.no-access-error-boundary__background {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
}

.no-access-error-boundary__content {
    position: absolute;
    font-family: Georgia;
    color: #F7F7F7;
    top: 10%;
    padding-left: var(--cla-size-8);
    padding-right: var(--cla-size-8);
}

.no-access-error-boundary__logo {
    width: 64px;
    height: 64px;
}

.no-access-error-boundary__divider {
    border: 2px solid #FFFFFF;
    width: 60px;
    margin-top: 40px;
    margin-left: 0px;
}

.no-access-error-boundary__title {
    font-size: 33px;
    font-weight: bold;
    margin-top: 70px;
}

.no-access-error-boundary__subtitle {
    font-size: 20px;
    margin-top: 75px;
}