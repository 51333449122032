.claNavLink {
  position: relative;
  font-family: 'museo-sans', sans-serif;
  font-style: normal; 
  font-size: 20px;
  text-decoration: none;
  font-weight: 200;

  color: #FFFFFF;

  padding: 20px 0rem 1rem 0rem;
  margin-left: 5px;
  margin-right: 25px; 
}

.claNavLink:hover {
  color: #FFFFFF;
}

.claNavLinkActive {
  font-weight: 600;
  border-bottom: 5px solid #76D2D3 !important;
  pointer-events: none;
}

.todoNotification {
  color: red
}

.appBar {
  background-color: #fff !important;
  box-shadow: none !important;
  padding: 0 30px;
}
 