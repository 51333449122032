:root {
    --uoe-datagrid-border-props: 1px solid rgba(224, 224, 224, 1);
}

.border-top-gray {
    border-top: var(--uoe-datagrid-border-props);
}

.border-bottom-gray {
    border-bottom: var(--uoe-datagrid-border-props);
}

.border-y-gray {
    border-top: var(--uoe-datagrid-border-props);
    border-bottom: var(--uoe-datagrid-border-props);
}