.vf-section-wrapper {
  position: relative;
  padding: 10px 0px 10px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'museo-sans';
}

.vf-section {
  background-color: white;
  width: 100%;
  border: 1px solid rgba(151, 151, 151, 0.25);
  border-radius: 4px;
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
}

.vf-section .section-header-wrapper {
  font-family: 'Georgia';
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: #2e334e;
}

.vf-section .section-header-wrapper .section-header {
  display: flex;
  gap: 6px;
  align-items: center;
  flex-grow: 1;
}

.vf-section-table-label-wrapper {
    padding: 10px;
  }
  
  .vf-section-table-label {
    color: #2e334e !important;
    white-space: 'normal';
    width: 'max-content';
  }
  
  .vf-section-formula-label {
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    color: #595959;
  }
  
  .vf-section-formula-value {
    font-weight: 400;
    font-size: 17px;
    line-height: 32px;
  }
  
  .vf-section-blank {
    background-color: transparent;
    border-color: transparent;
  }
  
  .field_wrapper_locked_tooltip_title {
    display: flex;
    flex-direction: row;
    padding: .6rem;
  }
  
  .person {
    --avatar-size: 2.8rem;
    color: #565656;
  }
  
  .vf-section-table-container {
    overflow: auto;
  }

  .vf-section-inner {
    background-color: transparent;
    width: 100%;
    height: 100%;
    min-height: 15vh;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    font-size: 18px;
    font-weight: 600;
  }