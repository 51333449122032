.vf-rendered-field {
  padding: 8px;
}

.vf-rendered-field-wrapper {
  position: relative;
  padding: 24px;
  box-sizing: border-box;
}

.field__wrapper {
  display: flex;
  align-items: center;
  margin: 10px 0;
  width: 100%;
}
